<template>
  <div class="JudgeList">
    <div
      class="item"
      v-for="(item, index) in data"
      :key="index"
      @click="toJudgeDetail(item.id)"
    >
      <img class="img" v-if="item.list_img" :src="item.list_img" />
      <img class="img" v-else src="@/assets/images/no.jpg" />
      <div class="name">{{ item.first_name + ' ' + item.last_name }}</div>
      <div class="company">{{ item.position }}</div>
      <div class="title">{{ item.region }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JudgeList',
  props: {
    data: [],
    cat: String,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    toJudgeDetail(id) {
      console.log(this.cat);
      let name = 'AwardsJudgesDetail';
      if (this.cat == 'Speaks') {
        name = 'FestivalSpeakersDetail';
      }
      this.$router.push({
        name,
        query: { id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.JudgeList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item:hover {
    background-color: #dce0e7;
  }
  .item {
    cursor: pointer;
    flex: 0 0 16%;
    padding: 1%;
    line-height: 25px;

    margin-right: calc(10% / 4);
    margin-bottom: calc(10% / 4);
  }

  .item:nth-child(5n) {
    margin-right: 0;
  }

  .item:last-child {
    margin-right: auto;
  }
  .img {
    display: block;
    min-height: 187px;
    max-width: 187px;
    width: 100%;
  }
  .name {
    font-size: 22px;
    margin: 10px 0;
  }
  .company {
    font-size: 20px;
  }
  .title {
    font-style: italic;
  }
}
@media (max-width: 950px) {
  .JudgeList {
    .item {
      flex: 0 0 45%;
      padding: 1%;

      margin-right: calc(10% / 2);
      margin-bottom: calc(10% / 2);
    }

    .item:nth-child(2n) {
      margin-right: 0;
    }

    .name {
      font-size: 20px;
      margin: 10px 0;
    }
    .company {
      font-size: 14px;
    }
    .title {
      font-size: 14px;
    }
  }
}
</style>
